
import Vue from 'vue';

export default Vue.extend({
  props: ['enabled', 'healthy'],
  computed: {
    statusText(): string {
      if (!this.healthy) return 'Invalid';
      return this.enabled ? 'Enabled' : 'Disabled';
    },
    enabledIcon(): string {
      if (!this.healthy) return 'error';
      return this.enabled ? 'check_circle' : 'error';
    },
    enabledClass(): string {
      if (!this.healthy) return 'red--text';
      return this.enabled ? 'green--text' : 'red--text';
    },
  },
});
